import { RaceType } from "../../../types";
import RaceImage from "../../RaceImage";

function RaceDetailsBottomRace({ race }: { race: RaceType }) {
  return (
    <div className=" my-12 w-4/6 mx-auto p-6 h-52 border-gray-400 border rounded-3xl">
      <div className="h-5/6">
        <RaceImage race={race} />
      </div>
      <div className="font-titillium text-xs my-2 uppercase">
        {race.Circuit.circuitName}
      </div>
    </div>
  );
}

export default RaceDetailsBottomRace;
