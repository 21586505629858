import { GridRacesProps } from "../../../types";
import GridRacesItems from "./GridRacesItems";

function GridRaces({ races, year }: GridRacesProps) {
  return (
    <div className="grid gap-4 gap-y-12 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 w-full py-4 h-full w-11/12">
      {races.map((race) => (
        <GridRacesItems race={race} year={year} />
      ))}
    </div>
  );
}

export default GridRaces;
