import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import GridDrivers from "../components/Grids/Drivers/GridDrivers";
import { getYearNow } from "../functions";
import { StandingList } from "../types";

export function Drivers() {
  const { year = getYearNow() } = useParams<string>();
  const [driversList, setDriversList] = useState<StandingList[]>([]);
  const url: string =
    "https://ergast.com/api/f1/" + year + "/driverStandings.json";

  useEffect(() => {
    fetch(url)
      .then((response) => response.json())
      .then((response) =>
        setDriversList(
          response.MRData.StandingsTable.StandingsLists[0].DriverStandings
        )
      )
      .catch((error) => console.log("Erreur : " + error));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="overflow-x-hidden pt-40 h-full flex flex-col  items-center ">
      <h1 className="my-6 font-bold font-f1Black text-6xl   border-t-8 border-r-8 border-black rounded-tr-xl   p-4 pt-8 w-11/12">
        F1 Drivers {year.toString()}
      </h1>
      <GridDrivers drivers={driversList} year={year} />
    </div>
  );
}
