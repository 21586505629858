import { GridDriverDetailsProps } from "../../../types";
import GridDriverDetailsItems from "./GridDriverDetailsItems";

function GridDriverDetails({ races, year }: GridDriverDetailsProps) {
  return (
    <div>
      <div className="grid grid-cols-3 md:grid-cols-5 w-5/6 mx-auto text-gray-500 py-4 ">
        <div className="ml-4">pos </div>
        <div className="md:col-span-3">country</div>
        <div className="ml-auto pr-4">fastest</div>
      </div>
      <div className="grid   grid-cols-1 w-full py-4 h-full">
        {races.map((race, index) => (
          <GridDriverDetailsItems
            key={race.Circuit.circuitId}
            index={index}
            race={race}
          />
        ))}
      </div>
    </div>
  );
}

export default GridDriverDetails;
