// import {  getLinkImageDriver } from "../functions";
import { PodiumRacesPassedProps } from "../types";
import DriverImagePodium from "./DriverImagePodium";
import LogoPodium from "./LogoPodium";

function PodiumRacesPassed({ raceResults }: PodiumRacesPassedProps) {
  const places: number[] = [1, 0, 2];
  const size: number[] = [5.5, 6, 5];

  return (
    <div className="grid gap-1 grid-cols-3 mx-auto">
      {places.map((place, index) =>
        raceResults.length > 0 ? (
          <div key={place} className="  relative mt-8 ">
            <div
              className={
                "pattern-stripe before:content-[''] before:w-full before:h-3/4 before:bg-black before:bottom-0 before:absolute before:rounded-t-lg  w-full absolute bottom-6 flex justify-center h-20"
              }
              style={{ height: size[index] + "rem" }}
            >
              <DriverImagePodium driver={raceResults[place].Driver} />
            </div>
            <div className="flex justify-center items-center rounded-br border-b-1 border-r-1 border-gray-400 h-6 ">
              <LogoPodium place={place} raceResults={raceResults} />
              <div className="font-bold">
                {raceResults[place].Driver.familyName
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
                  .toUpperCase()
                  .substring(0, 3)}
              </div>
            </div>
          </div>
        ) : (
          ""
        )
      )}
    </div>
  );
}

export default PodiumRacesPassed;
