import { getLinkImageRace } from "../functions";
import { RaceType } from "../types";

function RaceImage({ race }: { race: RaceType }) {
  return (
    <div className="pattern-cross before:absolute before:w-full before:h-full relative w-full h-full">
      <img
        src={getLinkImageRace(race)}
        alt=""
        className="h-full mx-auto object-contain"
      />
    </div>
  );
}

export default RaceImage;
