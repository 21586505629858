import {
  colorTeam,
  ConstructorType,
  DriverType,
  nationalityJson,
  RaceType,
  StandingList,
} from "../types";
export const monthNames: string[] = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
export function capitalizeFirstLetter(string: string): string {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
export function lowFirstLetter(string: string): string {
  return string.charAt(0).toLowerCase() + string.slice(1);
}

export function getLinkImageDriver(driver: DriverType): string {
  const n: number = 1;
  const givN: string = driver.givenName
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "");
  const famN: string = driver.familyName
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "");
  return (
    "https://www.formula1.com/content/dam/fom-website/drivers/" +
    givN.charAt(0).toUpperCase() +
    "/" +
    givN.substring(0, 3).toUpperCase() +
    famN.substring(0, 3).toUpperCase() +
    "0" +
    n.toString() +
    "_" +
    capitalizeFirstLetter(givN) +
    "_" +
    capitalizeFirstLetter(famN) +
    "/" +
    lowFirstLetter(givN.substring(0, 3)) +
    lowFirstLetter(famN.substring(0, 3)) +
    "0" +
    n.toString() +
    ".png.transform/2col/image.png"
  );
}
export function getLinkImageDriverById(driver: DriverType): string {
  const n: number = 1;
  const givN: string = driver.givenName
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "");
  const famN: string = driver.familyName
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "");
  return (
    "https://www.formula1.com/content/dam/fom-website/drivers/" +
    givN.charAt(0).toUpperCase() +
    "/" +
    givN.substring(0, 3).toUpperCase() +
    famN.substring(0, 3).toUpperCase() +
    "0" +
    n.toString() +
    "_" +
    capitalizeFirstLetter(givN) +
    "_" +
    capitalizeFirstLetter(famN) +
    "/" +
    lowFirstLetter(givN.substring(0, 3)) +
    lowFirstLetter(famN.substring(0, 3)) +
    "0" +
    n.toString() +
    ".png.transform/2col/image.png"
  );
}
export function getLinkImageFace(driver: StandingList): string {
  //https://www.formula1.com/content/fom-website/en/drivers/max-verstappen/_jcr_content/image.img.1536.medium.jpg/1646819045507.jpg
  // https://www.formula1.com/content/fom-website/en/drivers/charles-leclerc/_jcr_content/image.img.1920.medium.jpg/1646818893219.jpg
  const n: number = 1;
  const givN: string = driver.Driver.givenName
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "");
  const famN: string = driver.Driver.familyName
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "");
  return (
    "https://www.formula1.com/content/dam/fom-website/drivers/" +
    givN.charAt(0).toUpperCase() +
    "/" +
    givN.substring(0, 3).toUpperCase() +
    famN.substring(0, 3).toUpperCase() +
    "0" +
    n.toString() +
    "_" +
    capitalizeFirstLetter(givN) +
    "_" +
    capitalizeFirstLetter(famN) +
    "/" +
    lowFirstLetter(givN.substring(0, 3)) +
    lowFirstLetter(famN.substring(0, 3)) +
    "0" +
    n.toString() +
    ".png.transform/2col/image.png"
  );
}
export function getLinkLogoTeam(team: ConstructorType, year: string): string {
  //https://www.formula1.com/content/dam/fom-website/teams/2022/mercedes-logo.png.transform/2col/image.png
  let tempTeamN: string = team.Constructor.name;
  if (team.Constructor.name === "Red Bull") {
    tempTeamN = "Red Bull Racing";
  } else if (team.Constructor.name === "Alpine F1 Team") {
    tempTeamN = "Alpine";
  }
  const teamN: string = tempTeamN
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replaceAll("_", "-")
    .replaceAll(" ", "-")
    .toLowerCase();

  return (
    "https://www.formula1.com/content/dam/fom-website/teams/" +
    year +
    "/" +
    teamN +
    "-logo.png.transform/2col/image.png"
  );
}
export function getLinkImageCar(team: ConstructorType, year: string): string {
  let tempTeamN: string = team.Constructor.name;
  if (team.Constructor.name === "Red Bull") {
    tempTeamN = "Red Bull Racing";
  } else if (team.Constructor.name === "Alpine F1 Team") {
    tempTeamN = "Alpine";
  }
  const teamN: string = tempTeamN
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replaceAll("_", "-")
    .replaceAll(" ", "-")
    .toLowerCase();

  return (
    "https://www.formula1.com/content/dam/fom-website/teams/" +
    year +
    "/" +
    teamN +
    ".png.transform/4col/image.png"
  );
}
export function getLinkImageRace(race: RaceType): string {
  //* https://www.formula1.com/content/dam/fom-website/2018-redesign-assets/Track%20icons%204x3/Netherlands%20carbon.png.transform/2col/image.png

  let country: string = race.Circuit.Location.country;
  if (country === "UK") {
    country = "Great%20Britain";
  } else if (country === "UAE") {
    country = "Abu%20Dhabi";
  }

  return (
    "https://www.formula1.com/content/dam/fom-website/2018-redesign-assets/Track%20icons%204x3/" +
    country +
    "%20carbon.png.transform/2col/image.png"
  );
}
export function getLinkFlagRace(country: string): string {
  // let loc: string = race.Circuit.Location.country;
  let loc: string = country;
  if (loc === "USA") {
    loc = "united-states";
  } else if (loc === "UK") {
    loc = "great-britain";
  } else if (loc === "UAE") {
    loc = "great-britain";
  }
  loc = loc.toLowerCase().replace(" ", "-");
  //https://www.formula1.com/content/dam/fom-website/2018-redesign-assets/Flags%2016x9/bahrain-flag.png.transform/2col/image.png
  return (
    "https://www.formula1.com/content/dam/fom-website/2018-redesign-assets/Flags%2016x9/" +
    loc +
    "-flag.png.transform/2col/image.png"
  );
}
export const getLinkImageCity = (race: RaceType): string => {
  //https://www.formula1.com/content/dam/fom-website/2018-redesign-assets/Racehub%20header%20images%2016x9/Singapore.jpg.transform/9col/image.jpg
  let country: string = race.Circuit.Location.country;
  if (country === "UK") {
    country = "Great%20Britain";
  } else if (country === "UAE") {
    country = "Abu%20Dhabi";
  }
  const str =
    "https://www.formula1.com/content/dam/fom-website/2018-redesign-assets/Racehub%20header%20images%2016x9/" +
    country +
    ".jpg.transform/9col/image.jpg";
  return str;
};
export const getLinkImageYear = (year: string): string => {
  return (
    "https://www.formula1.com/content/dam/fom-website/2018-redesign-assets/year%20icon/" +
    year +
    ".png.transform/4col/image.png"
  );
};
export const getImageNumberPilote = (driver: DriverType): string => {
  // https://www.formula1.com/content/dam/fom-website/2018-redesign-assets/drivers/number-logos/SERPER01.png.transform/2col/image.png
  let givenName: string = driver.givenName
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "");
  let familyName: string = driver.familyName
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "");
  const link: string =
    "https://www.formula1.com/content/dam/fom-website/2018-redesign-assets/drivers/number-logos/" +
    givenName.substring(0, 3).toUpperCase() +
    familyName.substring(0, 3).toUpperCase() +
    "01.png.transform/2col/image.png";
  return link;
};
export function getDateFromString(stringDate: string): Date {
  const stringArr: string[] = stringDate.split("-");
  return new Date(
    parseInt(stringArr[0]),
    parseInt(stringArr[1]) - 1,
    parseInt(stringArr[2])
  );
}
export function combioneDateAndTime(date: string, time: string): Date {
  const stringArr: string[] = date.split("-");
  const timeArr: string[] = time.split(":");
  timeArr[2] = timeArr[2].slice(0, -1);
  const d = new Date();
  timeArr[0] = (parseInt(timeArr[0]) - d.getTimezoneOffset() / 60).toString();
  // console.log(timeArr);
  // console.log(d.getTimezoneOffset());

  return new Date(
    parseInt(stringArr[0]),
    parseInt(stringArr[1]) - 1,
    parseInt(stringArr[2]),
    parseInt(timeArr[0]),
    parseInt(timeArr[1]),
    parseInt(timeArr[2])
  );
}
export function isDateBeforeToday(date: Date): boolean {
  return new Date(date.toDateString()) < new Date(new Date().toDateString());
}

export function getYearNow(): string {
  return new Date().getFullYear().toString();
}
export const getColorOfConstructor = (constructorId: string): string => {
  const colorsTeams: colorTeam[] = require("./../assets/data/colorsTeams.json");

  return colorsTeams.find((c) => c.teamId === constructorId)?.color!;
};
export function getTimeFromDate(targetDate: Date): number {
  return new Date(targetDate).getTime();
}
export const getMonthDate = (race: RaceType): string => {
  getDateFromString(race.date).toLocaleString("en-US", {
    month: "long",
  });
  return getDateFromString(race.date)
    .toLocaleString("en-US", {
      month: "long",
    })
    .toUpperCase()
    .slice(0, 3);
};
export const getReturnValues = (countDown: number): string[] => {
  // calculate time left
  const days = minTwoDigits(Math.floor(countDown / (1000 * 60 * 60 * 24)));
  const hours = minTwoDigits(
    Math.floor((countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
  );
  const minutes = minTwoDigits(
    Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60))
  );
  const seconds = minTwoDigits(Math.floor((countDown % (1000 * 60)) / 1000));

  return [days, hours, minutes, seconds];
};
export const minTwoDigits = (n: number): string => {
  return (n < 10 ? "0" : "") + n;
};
export const removeNElementFromArray = (arr: any[], n: number): any[] => {
  const arr2 = arr.slice(n).concat(arr.slice(0, n));
  return arr2;
};
export const getNationalityFromCountry = (country: string): string => {
  const nationality: nationalityJson[] = require("./../assets/data/nationality.json");
  const countryFound: string = nationality.filter(
    (nation) => country === nation.Nationality
  )[0].Name;
  return countryFound;
};
