import { getLinkImageRace } from "../../../functions";
import { CardRacesCircuitProps } from "../../../types";

function CardRacesCircuit({ race }: CardRacesCircuitProps) {
  return (
    <div
      className="pattern-cross before:content-[''] before:opacity-50 before:pr-2 before:w-full  w-full before:h-20 before:absolute relative w-full h-20  bg-contain bg-no-repeat bg-center "
      style={{
        backgroundImage: `url(${getLinkImageRace(race)})`,
      }}
    ></div>
  );
}

export default CardRacesCircuit;
