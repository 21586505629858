import { useState } from "react";
import { Link } from "react-router-dom";
import { getColorOfConstructor } from "../../../functions";
import { GridDriversItemsProps } from "../../../types";
import CardDriversNationality from "./../../Cards/Drivers/CardDriversNationality";
import CardDriversPhoto from "./../../Cards/Drivers/CardDriversPhoto";
import CardDriversPoints from "./../../Cards/Drivers/CardDriversPoints";
import CardSeparator from "./../../CardSeparator";

function GridDriversItems({ driver, year, index }: GridDriversItemsProps) {
  const [isHovering, setIsHovering] = useState<boolean>(false);
  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };
  return (
    <div
      className={
        (index < 3 ? "xl:col-span-4 " : "xl:col-span-3 ") +
        " w-full h-96 mx-auto relative pr-4  border-t-2 border-r-2 rounded-tr-lg transition transition-all "
      }
      style={{
        borderColor: isHovering
          ? getColorOfConstructor(driver.Constructors[0].constructorId)
          : "black",
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Link to={"/driver/" + year + "/" + driver.Driver.driverId}>
        <CardDriversPoints driver={driver} />
        <CardSeparator />
        <CardDriversNationality driver={driver} />
        <CardSeparator />
        <CardDriversPhoto driver={driver} index={index} />
      </Link>
    </div>
  );
}
// eslint-disable-next-line no-lone-blocks

export default GridDriversItems;
