import { useEffect, useRef, useState } from "react";
import Countdown from "../components/Countdown";
import {
  combioneDateAndTime,
  getDateFromString,
  isDateBeforeToday,
} from "../functions";
import { RaceType } from "../types";
import max from "./../assets/img/backgrounds/max.jpg";
import redbullCar from "./../assets/img/backgrounds/redbullCar.jpg";
import lando from "./../assets/img/backgrounds/lando.jpg";
import mclaren from "./../assets/img/backgrounds/mclaren.jpg";

const Home = () => {
  const url: string = "https://ergast.com/api/f1/current.json";
  const [races, setRaces] = useState<RaceType[]>([]);
  const [nextRace, setNextRace] = useState<RaceType>();

  const pictureArray = [max, redbullCar, lando, mclaren];
  const [currentImageIndex, setCurrentImageIndex] = useState(
    Math.floor(Math.random() * pictureArray.length)
  );
  const changeImage = () => {
    const randomNumber = currentImageIndex;
    setCurrentImageIndex(randomNumber);
  };
  const countDownDate = useRef<number>(new Date().getTime());
  const [countDown, setCountDown] = useState<number>(
    countDownDate.current - new Date().getTime()
  );

  useEffect(() => {
    fetch(url)
      .then((response) => response.json())
      .then((response) => setRaces(response.MRData.RaceTable.Races))
      .catch((error) => alert("Erreur : " + error));

    changeImage();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    for (let i = 0; i < races.length; i++) {
      const race = races[i];
      if (!isDateBeforeToday(getDateFromString(race.date))) {
        setNextRace(race);
        return;
      }
    }
  }, [races]);

  useEffect(() => {
    //#countdown
    const interval = setInterval(() => {
      setCountDown(countDownDate.current - new Date().getTime());
    }, 1000);

    return () => clearInterval(interval);
  }, [countDownDate]);

  useEffect(() => {
    //* give the date of the gp
    if (nextRace) {
      countDownDate.current = new Date(
        combioneDateAndTime(nextRace.date, nextRace.time)
      ).getTime();
    }
  }, [nextRace]);

  return (
    <div
      style={{
        background: `linear-gradient(0deg, rgba(2, 2, 2, 0.5), rgba(2, 2, 2, 0.5)), url( ${pictureArray[currentImageIndex]})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
      className="pt-24 max-h-screen h-screen w-full relative "
    >
      <div className=" mt-24 mx-2 lg:ml-12 ">
        <div className="font-bold text-white text-4xl xl:text-6xl  lg:w-1/2">
          Next Race in the {new Date().getFullYear()} season : The{" "}
          {nextRace?.raceName}
        </div>
        <Countdown countDown={countDown} />
      </div>
    </div>
  );
};

export default Home;
