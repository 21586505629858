import { DriverType } from "../../../types";

function DriverDetailsName({ driver }: { driver: DriverType }) {
  return (
    <div className="sm:col-span-2">
      <div className="text-gray-500 font-f1 text-xl">
        {driver.permanentNumber}
      </div>
      <div className=" font-f1WebBold text-xl ">
        {driver.givenName} {driver.familyName}
      </div>
    </div>
  );
}

export default DriverDetailsName;
