import { RaceType } from "../../../types";
import RaceDetailsProgram from "./RaceDetailsProgram";

function RaceDetailsProgramContainer({ race }: { race: RaceType }) {
  const raceHasSprint: boolean = race.Sprint !== undefined;
  const programList: string[] = raceHasSprint
    ? ["race", "sprint", "practice 2", "qualifying", "practice 1"]
    : ["race", "qualifying", "practice 3", "practice 2", "practice 1"];
  return (
    <div className="bg-gray-200 pb-4">
      <div className="w-11/12 mx-auto">
        <div className="font-f1WebBold py-2 lg:py-4 text-2xl lg:text-4xl uppercase">
          race Weekend
        </div>
        <div className=" border-f1   py-2 lg:py-6 border-0 md:border-8 md:border-l-0 md:pr-6  rounded-tr-3xl rounded-br-3xl">
          {programList.map((program) => (
            <RaceDetailsProgram race={race} event={program} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default RaceDetailsProgramContainer;
