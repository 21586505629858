import {
  getDateFromString,
  getLinkImageCity,
  getLinkImageRace,
  getLinkImageYear,
  minTwoDigits,
  monthNames,
} from "../../../functions";
import { RaceType } from "../../../types";
import RaceImage from "../../RaceImage";
import RaceDetailsBackgroundImage from "./RaceDetailsBackgroundImage";
import RaceDetailsBottomRace from "./RaceDetailsBottomRace";
import RaceDetailsProgram from "./RaceDetailsProgram";
import RaceDetailsProgramContainer from "./RaceDetailsProgramContainer";

function RaceDetailsNotPassed({
  race,
  year,
}: {
  race: RaceType;
  year: string;
}) {
  return (
    <div>
      <RaceDetailsProgramContainer race={race} />
      <RaceDetailsBottomRace race={race} />
    </div>
  );
}

export default RaceDetailsNotPassed;
