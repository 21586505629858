import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import GridRaces from "../components/Grids/Races/GridRaces";
import { getYearNow } from "../functions";
import { RaceType } from "../types";

const Races = () => {
  const { year = getYearNow() } = useParams<string>();
  const url: string = "https://ergast.com/api/f1/" + year + ".json";
  const [races, setRaces] = useState<RaceType[]>([]);

  useEffect(() => {
    fetch(url)
      .then((response) => response.json())
      .then((response) => setRaces(response.MRData.RaceTable.Races))
      .catch((error) => alert("Erreur : " + error));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="pt-40 w-full h-full flex flex-col justify-center items-center">
      <h1 className="my-6 font-bold font-f1Black text-6xl inline-block border-t-8 border-r-8 border-black rounded-tr-xl w-11/12  p-4 ">
        F1 Schedule {year.toString()}
      </h1>
      <GridRaces races={races} year={year} />
    </div>
  );
};

export default Races;
