import { getLinkImageDriver } from "../../../functions";
import { DriverType, RaceResultsDriversType } from "../../../types";
import DriverImage from "../../DriverImage";
import DriverDetailsName from "./DriverDetailsName";
import DriverDetailsStats from "./DriverDetailsStats";

function DriverDetailsTopInfo({
  driver,
  totalRaces,
}: {
  driver: DriverType;
  totalRaces: RaceResultsDriversType[];
}) {
  const imgSrc: string = getLinkImageDriver(driver);

  return (
    <div className="grid gap-4 grid-cols-1 sm:grid-cols-2  pt-4">
      <DriverDetailsName driver={driver} />
      <DriverImage imgSrc={imgSrc} />
      <DriverDetailsStats totalRaces={totalRaces} />
    </div>
  );
}

export default DriverDetailsTopInfo;
