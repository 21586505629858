import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getDateFromString, isDateBeforeToday } from "../../../functions";
import { GridRacesItemsProps, ResultsType } from "../../../types";
import CardRacesCircuit from "./../../Cards/Races/CardRacesCircuit";
import CardRacesLocation from "./../../Cards/Races/CardRacesLocation";
import CardRacesTime from "./../../Cards/Races/CardRacesTime";
import CardSeparator from "./../../CardSeparator";
import PodiumRacesPassed from "./../../PodiumRacesPassed";

function GridRacesItems({ race, year }: GridRacesItemsProps) {
  const passed: boolean = isDateBeforeToday(getDateFromString(race.date));
  const [raceResults, setRaceResults] = useState<ResultsType[]>([]);
  useEffect(() => {
    if (passed) {
      const url: string =
        "https://ergast.com/api/f1/" +
        year +
        "/" +
        race.round +
        "/results.json";
      fetch(url)
        .then((response) => response.json())
        .then((response) =>
          setRaceResults(response.MRData.RaceTable.Races[0].Results)
        )
        .catch((error) => console.log("Erreur : " + error));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [passed]);
  return (
    <div className=" relative  h-80  pr-2 after:rounded-tr-lg hover:after:border-f1 font-bold mx-auto after:transition-all after:duration-200 after:ease-in-out after:absolute after:border-r-2 after:border-t-2 after:border-black after:content-[''] after:w-3/4 after:h-full after:top-0 after:right-0 ">
      <Link
        to={"/race/" + year + "/" + race.Circuit.circuitId}
        className=" inline-block  h-full relative z-30"
      >
        <CardRacesTime race={race} />
        <CardSeparator />
        <CardRacesLocation race={race} year={year} />
        <CardSeparator />
        <div className="absolute bottom-0 pr-2 w-full ">
          {passed ? (
            <PodiumRacesPassed raceResults={raceResults} />
          ) : (
            <CardRacesCircuit race={race} />
          )}
        </div>
      </Link>
    </div>
  );
}

export default GridRacesItems;
