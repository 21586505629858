import { getLinkImageDriver } from "../../../functions";
import { CardTeamsProps, StandingList } from "../../../types";
import Unknow from "./../../../assets/img/unknow.png";

function CardTeamsDrivers({ constructorItem, driversList }: CardTeamsProps) {
  const driversInTeam: StandingList[] = driversList!
    .filter(
      (driver) =>
        driver.Constructors[0].constructorId ===
        constructorItem.Constructor.constructorId
    )
    .slice(0, 2);
  return (
    <div className="grid grid-cols-2 gap-4 flex-row ">
      {driversInTeam.map((driver) => (
        <div
          key={driver.Driver.driverId}
          className="flex py-2 lg:py-0  justify-between border-b-2 border-r-2 border-gray-500 rounded-br-lg"
        >
          <div className="flex items-center">
            <div className="uppercase font-light text-xs md:text-sm">
              {driver.Driver.givenName}
            </div>
            <div className="uppercase font-f1WebBold tracking-wide  text-sm md:text-lg ml-2">
              {driver.Driver.familyName}
            </div>
          </div>
          <div>
            <img
              className="h-12 hidden lg:block"
              src={getLinkImageDriver(driver.Driver)}
              alt={getLinkImageDriver(driver.Driver)}
              srcSet=""
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = Unknow;
              }}
            />
          </div>
        </div>
      ))}
    </div>
  );
}

export default CardTeamsDrivers;
