import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import GridTeams from "../components/Grids/Teams/GridTeams";
import { ConstructorType } from "../types";

const Teams = () => {
  const { year = "current" } = useParams<string>();
  const url: string =
    "https://ergast.com/api/f1/" + year + "/constructorStandings.json";
  const [constructors, setConstructors] = useState<ConstructorType[]>([]);

  useEffect(() => {
    fetch(url)
      .then((response) => response.json())
      .then((response) =>
        setConstructors(
          response.MRData.StandingsTable.StandingsLists[0].ConstructorStandings
        )
      )
      .catch((error) => alert("Erreur : " + error));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="overflow-x-hidden pt-40 w-full h-full flex flex-col justify-center items-center mb-24">
      <h1 className=" my-6 font-bold font-f1Black text-6xl inline-block border-t-8 border-r-8 border-black rounded-tr-xl w-11/12  p-4 ">
        F1 Teams {year.toString()}
      </h1>
      <GridTeams constructors={constructors} year={year} />
    </div>
  );
};

export default Teams;
