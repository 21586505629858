import Unknow from "./../assets/img/unknow.png";
import { getLinkImageDriver } from "../functions";

function DriverImagePodium({ driver }: any) {
  return (
    <>
      <img
        // src={Unknow}
        src={getLinkImageDriver(driver)}
        alt={getLinkImageDriver(driver)}
        srcSet=""
        onError={({ currentTarget }) => {
          currentTarget.onerror = null; // prevents looping
          currentTarget.src = Unknow;
        }}
        className=" relative z-30  bottom-0 h-full object-cover"
      />
      <img
        // src={Unknow}
        src={getLinkImageDriver(driver)}
        alt={getLinkImageDriver(driver)}
        srcSet=""
        onError={({ currentTarget }) => {
          currentTarget.onerror = null; // prevents looping
          currentTarget.src = Unknow;
        }}
        className="absolute z-20 blur bottom-2 h-5/6 object-cover"
      />
    </>
  );
}

export default DriverImagePodium;
