import { getReturnValues } from "../functions";
import { CountdownProps } from "../types";
import Clock from "./Clock";

function Countdown({ countDown }: CountdownProps) {
  const [days, hours, minutes, seconds]: string[] = getReturnValues(countDown);
  return (
    <div className="flex justify-between items-center mt-24 md:w-5/6 md:mx-auto lg:w-1/2  lg:mt-12 text-4xl lg:mx-0">
      <Clock timeLabel="days" timeValue={days} />
      <div className="hidden lg:pb-10">:</div>
      <Clock timeLabel="hours" timeValue={hours} />
      <div className="hidden lg:pb-10">:</div>
      <Clock timeLabel="minutes" timeValue={minutes} />
      <div className="hidden lg:pb-10">:</div>
      <Clock timeLabel="secondes" timeValue={seconds} />
    </div>
  );
}

export default Countdown;
