import { CardRacesLocationProps } from "../../../types";

function CardRacesLocation({ race, year }: CardRacesLocationProps) {
  return (
    <>
      {" "}
      <h1 className="font-bold text-2xl">
        {race.Circuit.Location.country}{" "}
        <span className="text-f1 font-mono">{">"}</span>
      </h1>
      <h2 className="pr-6 h-12">
        FORMULA 1 - {race.raceName.toUpperCase()} {year}
      </h2>
      <h3 className="text-gray-400 font-light">{race.Circuit.circuitName}</h3>
    </>
  );
}

export default CardRacesLocation;
