import { useState } from "react";

import { getColorOfConstructor } from "../../../functions";
import { GridTeamsItemsProps, StandingList } from "../../../types";
import CardSeparator from "./../../CardSeparator";
import CardTeamsPoints from "./../../Cards/Teams/CardTeamsPoints";
import CardTeamsName from "./../../Cards/Teams/CardTeamsName";
import CardTeamsBottomContent from "./../../Cards/Teams/CardTeamsBottomContent";
import { Link } from "react-router-dom";

function GridTeamsItems({
  constructorItem,
  year,
  driversList,
}: GridTeamsItemsProps) {
  const driversInTeam: StandingList[] = driversList
    .filter(
      (driver) =>
        driver.Constructors[0].constructorId ===
        constructorItem.Constructor.constructorId
    )
    .slice(0, 2);
  const colorOfConstructor: string = getColorOfConstructor(
    constructorItem.Constructor.constructorId
  );
  const [isHovering, setIsHovering] = useState<boolean>(false);
  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  return (
    <div
      className=" w-full h-96 mx-auto relative pr-4  border-t-2 border-r-2 rounded-tr-lg  transition-all "
      style={{
        borderColor: isHovering ? colorOfConstructor : "black",
        // borderRightWidth: isHovering ? 4 : "",
        // borderTopWidth: isHovering ? 4 : "",
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {/* <Link
        to={
          "/constructor/" +
          year +
          "/" +
          constructorItem.Constructor.constructorId
        }
        className=" inline-block w-full h-full relative z-50"
      > */}
      <CardTeamsPoints constructorItem={constructorItem} year={year} />
      <CardSeparator />
      <CardTeamsName constructorItem={constructorItem} year={year} />
      <CardSeparator />
      <CardTeamsBottomContent
        constructorItem={constructorItem}
        year={year}
        driversList={driversList}
      />
      {/* </Link> */}
    </div>
  );
}
export default GridTeamsItems;
