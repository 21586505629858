import { getColorOfConstructor, getLinkLogoTeam } from "./../../../functions";
import { CardTeamsProps } from "./../../../types";

function CardTeamsName({ constructorItem, year }: CardTeamsProps) {
  const colorOfConstructor: string = getColorOfConstructor(
    constructorItem.Constructor.constructorId
  );
  return (
    <div className="flex justify-between">
      <div className="flex items-center">
        <div
          style={{
            backgroundColor: colorOfConstructor,
          }}
          className="content-[''] h-5/6 w-2"
        ></div>
        <div className="ml-4 font-f1WebBold tracking-wide font-black text-2xl">
          {constructorItem.Constructor.name}
        </div>
      </div>
      <img
        src={getLinkLogoTeam(constructorItem, year!)}
        alt={getLinkLogoTeam(constructorItem, year!)}
        className=" h-12 rounded  "
        onError={({ currentTarget }) => {
          currentTarget.onerror = null; // prevents looping
          currentTarget.src =
            "https://www.formula1.com/content/dam/fom-website/teams/2019/haas-f1-team-logo.png.transform/2col/image.png";
        }}
      />
    </div>
  );
}

export default CardTeamsName;
