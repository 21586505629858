import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import GoBack from "../components/GoBack";
import GridDriverDetails from "../components/Grids/DriverDetails/GridDriverDetails";
import DriverDetailsTopInfo from "../components/Views/DriverDetails/DriverDetailsTopInfo";
import { getLinkImageDriver } from "../functions";
import { DriverType, RaceResultsDriversType } from "../types";
import Unknow from "./../assets/img/unknow.png";

function DriverDetails() {
  const { driverId } = useParams<string>();
  const { year } = useParams<string>();
  const [driver, setDriver] = useState<DriverType>();
  const [races, setRaces] = useState<RaceResultsDriversType[]>([]);
  const [totalRaces, setTotalRaces] = useState<RaceResultsDriversType[]>([]);

  const urlDriverSeason: string =
    "https://ergast.com/api/f1/" + year + "/drivers/" + driverId + ".json";
  const urlTotalResults: string =
    "https://ergast.com/api/f1/drivers/" +
    driverId +
    "/results.json?limit=1000";
  const urlResults: string =
    "https://ergast.com/api/f1/" +
    year +
    "/drivers/" +
    driverId +
    "/results.json";

  useEffect(() => {
    console.log("first");
    fetch(urlDriverSeason)
      .then((response) => response.json())
      .then(
        (response) => setDriver(response.MRData.DriverTable.Drivers[0])
        // (response) => (driver.current = response.MRData.DriverTable.Drivers[0])
      )
      .catch((error) => console.log("Erreur : " + error));
    fetch(urlResults)
      .then((response) => response.json())
      .then((response) => setRaces(response.MRData.RaceTable.Races))
      .catch((error) => console.log("Erreur : " + error));
    fetch(urlTotalResults)
      .then((response) => response.json())
      .then((response) => setTotalRaces(response.MRData.RaceTable.Races))
      .catch((error) => console.log("Erreur : " + error));
    console.log("second");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="pt-24">
      <div className="w-5/6 mx-auto ">
        <GoBack />
        {driver ? (
          <DriverDetailsTopInfo driver={driver!} totalRaces={totalRaces} />
        ) : (
          ""
        )}
      </div>

      <GridDriverDetails races={races} year={year!} />
    </div>
  );
}

export default DriverDetails;
