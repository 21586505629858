import {
  capitalizeFirstLetter,
  combioneDateAndTime,
  getDateFromString,
  minTwoDigits,
  monthNames,
} from "../../../functions";
import { RaceType } from "../../../types";

function RaceDetailsProgram({
  race,
  event,
}: {
  race: RaceType;
  event: string;
}) {
  let date: string = "";
  let time: string = "";
  switch (event) {
    case "race":
      date = race.date;
      time = race.time;
      break;
    case "qualifying":
      date = race.Qualifying.date;
      time = race.Qualifying.time;
      break;
    case "practice 3":
      date = race.ThirdPractice?.date!;
      time = race.ThirdPractice?.time!;
      break;
    case "practice 2":
      date = race.SecondPractice.date;
      time = race.SecondPractice.time;
      break;
    case "practice 1":
      date = race.FirstPractice.date;
      time = race.FirstPractice.time;
      break;
    case "sprint":
      date = race.Sprint?.date!;
      time = race.Sprint?.time!;
      break;

    default:
      date = race.date;
      date = race.time;
      break;
  }
  console.log(race);
  return (
    <div className="grid grid-cols-6 bg-white my-1 h-14 w-full  rounded">
      <div className=" mx-auto flex flex-col justify-center items-center font-f1Web text-xs md:text-base ">
        <div>{minTwoDigits(getDateFromString(date).getDate())}</div>
        <div className="bg-gray-300 text-gray-500 inline-block rounded-full px-2">
          {monthNames[getDateFromString(date).getMonth()].slice(0, 3)}
        </div>
      </div>
      <div className="col-span-5  border-gray-300 my-auto border-dashed border-l-2 pl-2 ml-2 ">
        <div className="font-f1WebBold text-xl">
          {capitalizeFirstLetter(event)}
        </div>
        <div className="text-gray-500 text-sm">
          {combioneDateAndTime(date, time).getHours()}:
          {minTwoDigits(combioneDateAndTime(date, time).getMinutes())}
        </div>
      </div>
    </div>
  );
}

export default RaceDetailsProgram;
