import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import GoBack from "../components/GoBack";
import { ConstructorType } from "../types";

function TeamDetails() {
  const { constructorId } = useParams<string>();
  const { year = "current" } = useParams<string>();
  const [constructor, setConstructor] = useState<ConstructorType>();
  const url: string =
    "https://ergast.com/api/f1/" +
    year +
    "/constructors/" +
    constructorId +
    ".json";
  useEffect(() => {
    fetch(url)
      .then((response) => response.json())
      .then((response) => console.log(response.MRData))
      .catch((error) => alert("Erreur : " + error));
  }, []);

  return (
    <div className="pt-24">
      <GoBack />

      <div>{constructorId}</div>
    </div>
  );
}

export default TeamDetails;
