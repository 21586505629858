import { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { capitalizeFirstLetter, getYearNow } from "../functions";
import { YearType } from "../types";
import F1W from "./../assets/img/f1-white.svg";
import F1 from "./../assets/img/f1.svg";

function Header() {
  const location = useLocation();
  const nav: string[] = ["races", "drivers", "teams"];

  const { year } = useParams<string>();
  const url: string = "https://ergast.com/api/f1/seasons.json?limit=200";
  const [yearState, setYearState] = useState<string>(year || getYearNow);
  const [yearList, setYearList] = useState<YearType[]>([]);
  const [inHome, setInHome] = useState<boolean>(
    location.pathname === "/" + yearState ||
      location.pathname === "/" + year ||
      location.pathname === "/"
  );
  // const inHome: boolean =
  //   location.pathname === "/" + yearState ||
  //   location.pathname === "/" + year ||
  //   location.pathname === "/";

  useEffect(() => {
    fetch(url)
      .then((response) => response.json())
      .then((response) =>
        setYearList(response.MRData.SeasonTable.Seasons.sort().reverse())
      )
      .catch((error) => alert("Erreur : " + error));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setInHome(
      location.pathname === "/" + yearState ||
        location.pathname === "/" + year ||
        location.pathname === "/"
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
  return (
    <div
      className={
        (inHome ? "bg-transparent" : " bg-white") +
        " z-50 navigation-header fixed  w-full flex justify-around items-center p-8"
      }
    >
      <Link to={"/" + yearState}>
        {/* <img src={inHome ? F1W : F1} alt="Logo Formula 1" /> */}
        <img src={F1} alt="Logo Formula 1" />
      </Link>
      <nav
        className={
          (inHome ? "text-white" : "text-black") +
          " justify-self-center flex items-center  "
        }
      >
        {nav.map((navStr) => (
          <Link
            to={"/" + navStr + "/" + yearState}
            className={
              (location.pathname.includes(navStr)
                ? "border-b-2 border-white hover:border-b-2"
                : "") +
              (inHome ? "hover:border-white" : " hover:border-gray-800") +
              " mx-4 hover:border-b-2 "
            }
          >
            {capitalizeFirstLetter(navStr)}
          </Link>
        ))}
      </nav>
      <div className="">
        {inHome ? (
          <select
            size={1}
            value={yearState}
            onChange={(e: any) => setYearState(e.target.value)}
            className={
              (inHome ? " text-white" : "text-black") +
              " bg-transparent outline-0   "
            }
            style={{ scrollbarWidth: "none" }}
          >
            {yearList.map((year) => (
              <option
                key={year.season}
                value={year.season}
                className="bg-transparent text-black "
              >
                {year.season}
              </option>
            ))}
          </select>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default Header;
