import { getLinkFlagRace, getMonthDate } from "../../../functions";
import { CardRacesTimeProps } from "../../../types";
import DateRaceItems from "../../DateRaceItems";

function CardRacesTime({ race }: CardRacesTimeProps) {
  return (
    <>
      <h1 className="absolute -top-3 text-f1 ">Round {race.round}</h1>
      <div className="flex items-center justify-between mt-4">
        <DateRaceItems race={race} />
        <img
          src={getLinkFlagRace(race.Circuit.Location.country)}
          alt={getLinkFlagRace(race.Circuit.Location.country)}
          className="relative z-30  h-6 rounded"
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src =
              "https://www.formula1.com/content/dam/fom-website/2018-redesign-assets/Flags%2016x9/great-britain-flag.png.transform/2col/image.png";
          }}
        />
      </div>
      <div className="flex items-center mt-2">
        <h3 className="text-white bg-black rounded inline-block font-f1 p-1 text-xs">
          {getMonthDate(race)}
        </h3>
        <div
          className="w-full ml-2 rounded h-6 
      bg-cover bg-no-repeat bg-right"
          style={{
            backgroundImage: `url(${"https://www.formula1.com/etc/designs/fom-website/images/flag-asset.png"})`,
          }}
        ></div>
      </div>
    </>
  );
}

export default CardRacesTime;
