import { getLinkFlagRace } from "../../../functions";
import {
  GridDriverDetailsItemsProps,
  RaceResultsDriversType,
} from "../../../types";
import fastestIcon from "./../../../assets/img/fastest-lap.png";

function GridDriverDetailsItems({ race, index }: GridDriverDetailsItemsProps) {
  const hasTheFastestLap = (r: RaceResultsDriversType): boolean => {
    let fastest: boolean =
      r.Results[0].FastestLap === undefined
        ? false
        : r.Results[0].FastestLap.rank === "1";
    return fastest;
  };
  return (
    <div
      className={
        (index % 2 === 0
          ? "bg-gray-200 hover:bg-gray-300"
          : "hover:bg-gray-100") +
        "  w-5/6 mx-auto relative py-4 grid grid-cols-3 md:grid-cols-5 text-gray-700"
      }
    >
      <div className={" ml-4 font-titillium font-bold text-xl"}>
        {race.Results[0].position}
      </div>

      <div className=" col-span-1 w-11/12 flex items-center justify-between md:col-span-3">
        <div className="hidden md:block">{race.raceName}</div>
        <img
          src={getLinkFlagRace(race.Circuit.Location.country)}
          alt={race.Circuit.Location.country}
          className="relative z-30  h-6 rounded"
        />
      </div>
      <div className=" ml-auto my-auto pr-4">
        {hasTheFastestLap(race) ? (
          <img src={fastestIcon} alt="" className="h-8" />
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default GridDriverDetailsItems;
