import { useNavigate } from "react-router-dom";

function GoBack() {
  const navigate = useNavigate();

  return (
    <div
      onClick={() => navigate(-1)}
      className="py-4 inline-block hover:bg-gray-300 p-4  cursor-pointer font-bold font-f1Web "
    >
      Go back
    </div>
  );
}

export default GoBack;
