import { CardTeamsProps } from "./../../../types";

function CardTeamsPoints({ constructorItem }: CardTeamsProps) {
  return (
    <div className="flex flex-row justify-between items-center py-4">
      <div className="font-f1Black -top-4 text-6xl">
        {constructorItem.position}
      </div>
      <div className="flex flex-col items-center ">
        <div className="font-f1">{constructorItem.points}</div>
        <div className="w-full text-center text-white bg-black rounded inline-block font-f1 p-1 text-xs">
          pts
        </div>
      </div>
    </div>
  );
}

export default CardTeamsPoints;
