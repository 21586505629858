import { CardTeamsProps } from "./../../../types";
import CardTeamsCar from "./CardTeamsCar";
import CardTeamsDrivers from "./CardTeamsDrivers";

function CardTeamsBottomContent({
  constructorItem,
  year,
  driversList,
}: CardTeamsProps) {
  return (
    <div className=" absolute bottom-0 pr-2 w-full   ">
      <CardTeamsDrivers
        constructorItem={constructorItem}
        driversList={driversList}
      />
      <CardTeamsCar constructorItem={constructorItem} year={year} />
    </div>
  );
}

export default CardTeamsBottomContent;
