import {
  getDateFromString,
  getLinkImageCity,
  getLinkImageYear,
  minTwoDigits,
  monthNames,
} from "../../../functions";
import { RaceType } from "../../../types";

function RaceDetailsBackgroundImage({
  race,
  year,
}: {
  race: RaceType;
  year: string;
}) {
  return (
    <div
      style={{
        background: `linear-gradient(0deg, rgba(2, 2, 2, 0.5), rgba(2, 2, 2, 0.5)), url(${getLinkImageCity(
          race
        )})`,
      }}
      className="flex flex-col py-12 md:py-24 items-center bg-no-repeat bg-cover bg-gray-600"
    >
      <div className="text-white font-f1WebBold uppercase text-2xl md:text-8xl">
        {race.Circuit.Location.country}
      </div>
      <img
        src={getLinkImageYear(year!)}
        alt="year"
        className="h-8 my-4 md:h-12 md:my-6"
      />
      <div>
        <div className="text-white bg-gray-900 px-2 py-1 rounded-full text-xs md:text-base">
          {race.FirstPractice
            ? minTwoDigits(
                getDateFromString(race.FirstPractice.date).getDate()
              ) +
              " " +
              monthNames[
                getDateFromString(race.FirstPractice.date).getMonth()
              ].slice(0, 3) +
              " - "
            : ""}
          {minTwoDigits(getDateFromString(race.date).getDate())}{" "}
          {monthNames[getDateFromString(race.date).getMonth()].slice(0, 3)}
        </div>
      </div>
    </div>
  );
}

export default RaceDetailsBackgroundImage;
