import { getImageNumberPilote, getLinkImageDriver } from "../../../functions";
import { CardDriversProps } from "../../../types";
import Unknow from "./../../../assets/img/unknow.png";

function CardDriversPhoto({ driver, index }: CardDriversProps) {
  return (
    <div
      className={
        (index! < 3 ? "h-40 lg:h-52" : "h-40") +
        " absolute bottom-0 pr-2 w-full   "
      }
    >
      <div className="pattern-cross before:content-[''] before:pr-2 before:w-full before:bottom-0  before:h-5/6 before:absolute relative w-full h-full opacity-100 bg-contain bg-no-repeat bg-center z-10">
        <div className="relative bottom-0 h-full   ">
          <img
            className="absolute z-30  bottom-0  right-0 h-full"
            src={getLinkImageDriver(driver.Driver)}
            alt={getLinkImageDriver(driver.Driver)}
            srcSet=""
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = Unknow;
            }}
          />
          <img
            className="absolute z-20 blur bottom-4 h-5/6 right-0"
            src={getLinkImageDriver(driver.Driver)}
            alt={getLinkImageDriver(driver.Driver)}
            srcSet=""
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = Unknow;
            }}
          />
        </div>

        <div
          className={
            "absolute bottom-0 bg-white  w-1/4 " +
            (index! < 3 ? "xl:w-1/3" : "")
          }
        >
          <img
            src={getImageNumberPilote(driver.Driver)}
            alt={"number of " + driver.Driver.familyName}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src =
                "https://www.formula1.com/content/dam/fom-website/2018-redesign-assets/drivers/number-logos/PIEGAS01.png.transform/2col/image.png";
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default CardDriversPhoto;
