import { ClockProps } from "../types";

function Clock({ timeValue, timeLabel }: ClockProps) {
  return (
    <div className="inline-flex flex-col justify-center bg-f1 items-center text-white font-bold w-16 h-16 md:w-24 md:h-24 ">
      <div className=" text-xl py-2 lg:text-4xl lg:py-4">{timeValue}</div>
      <div className=" text-xs text-ellipsis pb-2 lg:text-sm font-light lg:pb-4">
        {timeLabel}
      </div>
    </div>
  );
}

export default Clock;
