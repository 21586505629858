import { BrowserRouter, Route, Routes } from "react-router-dom";
import Header from "../layout/Header";
import DriverDetails from "../view/DriverDetails";
import { Drivers } from "../view/Drivers";
import Home from "../view/Home";
import RaceDetails from "../view/RaceDetails";
import Races from "../view/Races";
import TeamDetails from "../view/TeamDetails";
import Teams from "../view/Teams";

function RoutesConfig() {
  const length: number = 15;
  let i: number = 0;
  let title: string[] = Array(length).fill("_");
  const car: string = "🏎️";
  setInterval(() => {
    title = Array(length).fill("_");
    const index: number = length - 1 - (i % length);
    title[index] = car;
    document.title = title.join("");
    i++;
  }, 500);

  return (
    <BrowserRouter>
      <div className={"  min-h-screen  "}>
        <Header />
        <div className=" h-full max-w-full">
          <Routes>
            <Route path="/">
              <Route index element={<Home />} />
              <Route path=":year" element={<Home />} />
            </Route>
            <Route path="/drivers/:year" element={<Drivers />} />

            {/* <Route path="/drivers">
              <Route index element={<Drivers />} />
              <Route path=":year" element={<Drivers />} />
            </Route> */}
            <Route path="/driver/:year">
              <Route index element={<DriverDetails />} />
              <Route path=":driverId" element={<DriverDetails />} />
            </Route>
            <Route path="/races">
              <Route index element={<Races />} />
              <Route path=":year" element={<Races />} />
            </Route>
            <Route path="/race/:year">
              <Route index element={<RaceDetails />} />
              <Route path=":circuitId" element={<RaceDetails />} />
            </Route>
            <Route path="/teams">
              <Route index element={<Teams />} />
              <Route path=":year" element={<Teams />} />
            </Route>
            <Route path="/constructor/:year">
              <Route index element={<TeamDetails />} />
              <Route path=":constructorId" element={<TeamDetails />} />
            </Route>
          </Routes>
        </div>
      </div>
    </BrowserRouter>
  );
}

export default RoutesConfig;
