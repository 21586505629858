import { getColorOfConstructor } from "../../../functions";
import { resultRaceType } from "../../../types";
import fastestIcon from "./../../../assets/img/fastest-lap.png";

function RaceDetailsResultDriver({
  result,
  index,
}: {
  result: resultRaceType;
  index: number;
}) {
  return (
    <div
      style={{
        borderBottomColor: getColorOfConstructor(
          result.Constructor.constructorId
        ),
      }}
      className={
        (index % 2 === 1 ? "bg-white" : "bg-gray-200") +
        " relative grid  grid-cols-6 py-4 h-14 hover:border-b-4  text-xs md:text-base  text-gray-700 font-titillium  pl-2"
      }
    >
      <div
        style={{
          backgroundColor: getColorOfConstructor(
            result.Constructor.constructorId
          ),
        }}
        className="absolute content-[''] h-4/6 w-1  top-1/2 bg-green-200 transform  -translate-y-1/2"
      ></div>
      <div className="flex items-center ">
        {result.position}
        {result.FastestLap?.rank === "1" ? (
          <img src={fastestIcon} alt="" className="h-6 ml-2" />
        ) : (
          ""
        )}
      </div>
      <div className="my-auto font-bold ">
        {result.Driver.givenName + " " + result.Driver.familyName}
      </div>
      <div className="uppercase my-auto">{result.Constructor.name}</div>
      <div className="font-light text-xs my-auto">{result.laps}</div>
      <div className="font-light text-xs my-auto">{result.Time?.time}</div>
      <div className="font-light text-xs my-auto">{result.points}</div>
    </div>
  );
}

export default RaceDetailsResultDriver;
