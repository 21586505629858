import { DriverType, RaceResultsDriversType } from "../../../types";

function DriverDetailsStats({
  totalRaces,
}: {
  totalRaces: RaceResultsDriversType[];
}) {
  return (
    <div className=" grid grid-cols-2 h-5/6 my-auto ">
      <div>Races Disputed :</div>
      <div> {totalRaces.length}</div>
      <div> Wins :</div>
      <div>
        {totalRaces.filter((race) => race.Results[0].position === "1").length}
      </div>
      <div>Podiums :</div>
      <div>
        {
          totalRaces.filter(
            (race) =>
              race.Results[0].position === "1" ||
              race.Results[0].position === "2" ||
              race.Results[0].position === "3"
          ).length
        }
      </div>
    </div>
  );
}

export default DriverDetailsStats;
