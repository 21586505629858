import { resultRaceType } from "../../../types";
import RaceDetailsResultDriver from "./RaceDetailsResultDriver";

function RaceDetailsPassed({ resultsRace }: { resultsRace: resultRaceType[] }) {
  return (
    <div className="w-full md:w-5/6 mx-auto my-8 ">
      <div className="grid grid-cols-6 uppercase text-xs text-gray-500">
        <div>pos</div>
        <div>Name</div>
        <div>Team</div>
        <div>laps</div>
        <div>time</div>
        <div>pts</div>
      </div>
      {resultsRace.map((result, index) => (
        <RaceDetailsResultDriver index={index} result={result} />
      ))}
    </div>
  );
}

export default RaceDetailsPassed;
