import { LogoPodiumProps } from "../types";
import { getColorOfConstructor } from "../functions";
import { ReactComponent as FirstLogo } from "./../assets/img/places/1.svg";
import { ReactComponent as SecondLogo } from "./../assets/img/places/2.svg";
import { ReactComponent as ThirdLogo } from "./../assets/img/places/3.svg";
function LogoPodium({ place, raceResults }: LogoPodiumProps) {
  return (
    <div className="w-1/3 mr-1 ">
      {place === 0 ? (
        <FirstLogo
          fill={getColorOfConstructor(
            raceResults[place].Constructor.constructorId
          )}
          className="h-full w-full"
        />
      ) : place === 1 ? (
        <SecondLogo
          fill={getColorOfConstructor(
            raceResults[place].Constructor.constructorId
          )}
          className="h-full w-full"
        />
      ) : (
        <ThirdLogo
          fill={getColorOfConstructor(
            raceResults[place].Constructor.constructorId
          )}
          className="h-full w-full"
        />
      )}
    </div>
  );
}
export default LogoPodium;
