import { CardDriversProps } from "../../../types";

function CardDriversPoints({ driver }: CardDriversProps) {
  return (
    <div className="flex flex-row justify-between items-center py-4">
      <div className="font-f1Black -top-4 text-6xl">{driver.position}</div>
      <div className="flex flex-col items-center ">
        <h1 className=" font-f1 ">{driver.points}</h1>
        <h1 className="w-full text-center text-white bg-black rounded inline-block font-f1 p-1 text-xs">
          pts
        </h1>
      </div>
    </div>
  );
}

export default CardDriversPoints;
