import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import fastestIcon from "./../assets/img/fastest-lap.png";

import {
  combioneDateAndTime,
  getDateFromString,
  getTimeFromDate,
  isDateBeforeToday,
  getLinkImageRace,
  minTwoDigits,
  getLinkImageCity,
  monthNames,
  getLinkImageYear,
} from "../functions";
import { RaceType, resultRaceType } from "../types";
import GoBack from "../components/GoBack";
import RaceDetailsPassed from "../components/Views/RaceDetails/RaceDetailsPassed";
import RaceDetailsNotPassed from "../components/Views/RaceDetails/RaceDetailsNotPassed";
import RaceDetailsBackgroundImage from "../components/Views/RaceDetails/RaceDetailsBackgroundImage";
function RaceDetails() {
  const isInitialMount = useRef(true);

  const navigate = useNavigate();
  const { circuitId } = useParams<string>();
  const { year } = useParams<string>();
  const [race, setRace] = useState<RaceType>();
  const [resultsRace, setResultsRace] = useState<resultRaceType[]>([]);
  const [passed, setPassed] = useState<boolean>(false);
  const url: string = "https://ergast.com/api/f1/" + year + ".json";

  useEffect(() => {
    fetch(url)
      .then((response) => response.json())
      .then((response) =>
        setRace(
          response.MRData.RaceTable.Races.filter(
            (raceRes: RaceType) => raceRes.Circuit.circuitId === circuitId
          )[0]
        )
      )
      .catch((error) => alert("Erreur : " + error));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      // Your useEffect code here to be run on update
      const passed: boolean = isDateBeforeToday(
        combioneDateAndTime(race?.date!, race?.time!)
      );
      const urlResult: string =
        "https://ergast.com/api/f1/" +
        year +
        "/" +
        race?.round +
        "/results.json";
      if (passed) {
        fetch(urlResult)
          .then((response) => response.json())
          .then((response) =>
            setResultsRace(response.MRData.RaceTable.Races[0].Results)
          )
          .catch((error) => alert("Erreur : " + error));
      }
    }
  }, [race]);
  return (
    <div className="pt-24 ">
      {race ? (
        <div>
          <RaceDetailsBackgroundImage race={race} year={year!} />

          {isDateBeforeToday(combioneDateAndTime(race?.date, race.time)) ? (
            <RaceDetailsPassed resultsRace={resultsRace} />
          ) : (
            <RaceDetailsNotPassed year={year!} race={race} />
          )}
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default RaceDetails;
