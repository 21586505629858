import { useEffect, useState } from "react";
import { GridTeamsProps, StandingList } from "../../../types";
import GridTeamsItems from "./GridTeamsItems";

function GridTeams({ constructors, year }: GridTeamsProps) {
  const [driversList, setDriversList] = useState<StandingList[]>([]);
  const url: string =
    "https://ergast.com/api/f1/" + year + "/driverStandings.json";

  useEffect(() => {
    fetch(url)
      .then((response) => response.json())
      .then((response) =>
        setDriversList(
          response.MRData.StandingsTable.StandingsLists[0].DriverStandings
        )
      )
      .catch((error) => console.log("Erreur : " + error));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="grid gap-4 gap-y-12 grid-cols-1 md:grid-cols-2  w-full py-4 h-full w-11/12">
      {constructors.map((constructor) => (
        <GridTeamsItems
          key={constructor.Constructor.constructorId}
          constructorItem={constructor}
          driversList={driversList}
          year={year}
        />
      ))}
    </div>
  );
}

export default GridTeams;
