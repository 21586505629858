import { GridDriversProps } from "../../../types";
import GridDriversItems from "./GridDriversItems";

function GridDrivers({ drivers, year }: GridDriversProps) {
  return (
    <div className=" w-11/12 max-w-screen">
      <div className="grid gap-4 gap-y-12 w-full py-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-12">
        {drivers.map((driver, index) => (
          <GridDriversItems
            key={driver.Driver.driverId}
            driver={driver}
            year={year}
            index={index}
          />
        ))}
      </div>
    </div>
  );
}

export default GridDrivers;
