import {
  getColorOfConstructor,
  getLinkFlagRace,
  getNationalityFromCountry,
} from "./../../../functions";
import { CardDriversProps } from "./../../../types";

function CardDriversNationality({ driver }: CardDriversProps) {
  const colorOfConstructor: string = getColorOfConstructor(
    driver.Constructors[0].constructorId
  );
  return (
    <div className="flex justify-between items-center">
      <div className="flex items-center">
        <div
          style={{
            backgroundColor: colorOfConstructor,
          }}
          className="content-[''] h-5/6 w-2"
        ></div>
        <div className=" ml-4">
          <div className="uppercase font-light">{driver.Driver.givenName}</div>
          <div className="uppercase font-f1WebBold tracking-wide font-black text-2xl">
            {driver.Driver.familyName}
          </div>
        </div>
      </div>
      <img
        src={getLinkFlagRace(
          getNationalityFromCountry(driver.Driver.nationality)
        )}
        alt={getLinkFlagRace(
          getNationalityFromCountry(driver.Driver.nationality)
        )}
        className="h-6 rounded relative z-30 "
      />
    </div>
  );
}

export default CardDriversNationality;
