import { getDateFromString } from "../functions";
import { DateRaceItemsProps } from "../types";

function DateRaceItems({ race }: DateRaceItemsProps) {
  return (
    <h2 className="font-f1 text-xl">
      {race.FirstPractice
        ? getDateFromString(race.FirstPractice.date).getUTCDate() + 1
        : "00"}{" "}
      - {getDateFromString(race.date).getUTCDate() + 1}
    </h2>
  );
}

export default DateRaceItems;
