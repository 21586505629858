import { getColorOfConstructor, getLinkImageCar } from "../../../functions";
import { CardTeamsProps } from "../../../types";

function CardTeamsCar({ constructorItem, year }: CardTeamsProps) {
  const colorOfConstructor: string = getColorOfConstructor(
    constructorItem.Constructor.constructorId
  );
  return (
    <div className="pattern-cross before:content-[''] before:pr-2 before:w-full before:bottom-0  before:h-5/6 before:absolute relative w-full h-40 opacity-100 bg-contain bg-no-repeat bg-center z-10">
      <div
        className="absolute w-10/12 h-12  top-3/4 transform -translate-y-1/2"
        style={{
          backgroundColor: colorOfConstructor,
        }}
      ></div>
      <img
        className="absolute z-30  bottom-0 h-2/3 left-8  lg:h-full object-contain"
        src={getLinkImageCar(constructorItem, year!)}
        alt={getLinkImageCar(constructorItem, year!)}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null; // prevents looping
          currentTarget.src =
            "https://www.formula1.com/content/dam/fom-website/teams/2019/haas-f1-team.png.transform/4col/image.png";
        }}
      />
    </div>
  );
}

export default CardTeamsCar;
